import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomNavbar from '../Components/CustomNavbar';
import MyCarousel from '../Components/CustomCarousel';
import Footer from '../Components/Footer';
import SearchBar from '../Components/SearchBar/SearchBar';
import images from '../assets/Images';
import { fetchProducts } from '../services/Prodcuts'; // Fixed typo in import

export default function AllProducts() {
  const slides = [
    { image: images.Banner },
    { image: images.Banner },
    { image: images.Banner },
  ];

  const [medicines, setMedicines] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12); // No need to update this state, so it's fixed
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const productContainerRef = useRef(null);

  // useCallback to memoize the fetch function
  const fetchProductData = useCallback(async () => {
    try {
      const data = await fetchProducts(currentPage, itemsPerPage, searchTerm);
      setMedicines(data.products);
      setTotalPages(data.totalPages);

      if (productContainerRef.current) {
        productContainerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  }, [currentPage, itemsPerPage, searchTerm]);

  useEffect(() => {
    fetchProductData();
  }, [fetchProductData]); // Depend on memoized fetch function

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1); // Reset to the first page on a new search
  };

  const handlePrevious = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNext = () => setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));

  return (
    <>
      <CustomNavbar />
      <SearchBar onSearch={handleSearch} onSearchClick={handleNext} />

      <MyCarousel slides={slides} />
      <div className="justify-center" id="product-container" ref={productContainerRef}>
        <Container>
          <Row>
            {medicines.map((data) => (
              <Col key={data.id} xs={6} sm={6} md={3} className="mb-4 p-2">
                <Link
                  to={'/productdetails'}
                  style={{ textDecoration: 'none', color: 'black' }}
                  state={{
                    id: data.id,
                    pname: data.name,
                    desc: data.desc,
                    images: data.images,
                    price: data.price,
                    specification: data.specification,
                    usageAndSafety: data.usageAndSafety,
                    warnings: data.warnings,
                    additionalInformation: data.additionalInformation,
                    precautions: data.precautions
                  }}
                >

                  <Card.Img variant="top" src={data.images[0]} alt={data.name} />
                  <Card.Body className="p-3">
                    <Card.Title>{data.name}</Card.Title>
                    <Card.Text>{data.desc}</Card.Text>
                    <Card.Text>
                      <strong>{data.price}</strong>
                    </Card.Text>
                  </Card.Body>

                </Link>
              </Col>
            ))}
          </Row>
        </Container>
        <div className="text-center">
          <button className="custom-button" onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          <span>
            {' '}
            Page {currentPage} of {totalPages}{' '}
          </span>
          <button className="custom-button" onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import AboutUs from './Screens/AboutUs';
import AllProducts from './Screens/AllProducts';
import ProductDetails from './Screens/ProductDetails';
import CheckOut from './Screens/CheckOut';
import 'react-toastify/ReactToastify.css'
import { ToastContainer } from 'react-toastify';
import PerceptionOrder from './Screens/PerceptionOrder';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import Conditions from './Screens/Conditions';
import DeliveryPolicy from './Screens/DeliveryPolicy';
import CancelationPolicy from './Screens/CancelationPolicy';
import Home from './Screens/Home';
import TrackOrder from './Screens/TrackOrder';
import OrderConfirmation from './Screens/OrderConfirmation';
import ScrollToTop from './Components/ScrollToTop';


function App() {
 

  return (
    <Router>
      <ScrollToTop/>
      <ToastContainer/>
      <Routes>
        <Route path='/about-us' element={<AboutUs />} />
        {/* <Route path='/aboutus' element={<AboutUs />} /> */}
        {/* <Route path='/home' element={<Home />} /> */}
        <Route path='/allproducts' element={<AllProducts />} />
        <Route path='/' element={<Home />} />       
        <Route path='/productdetails' element={<ProductDetails />} />
        <Route path='/checkout' element={<CheckOut />} />
        <Route path='/preSorted-order' element={<PerceptionOrder />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='terms-conditions' element={<Conditions />} />
        <Route path='/delivery-policy' element={<DeliveryPolicy />} />
        <Route path='/cancellation-policy' element={<CancelationPolicy/>} />
        <Route path='/track-order' element={<TrackOrder/>} />
        <Route path="/order-confirmation" element={<OrderConfirmation />} />
       







       
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Button, Offcanvas, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const CustomNavbar = () => {
    const totalItems = useSelector(state => state.cart.totalItems);

    const [navBackground, setNavBackground] = useState('transparent');
    const [boxShadow, setBoxShadow] = useState('none');

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY > 50) {
                setNavBackground('white');
                setBoxShadow('0 4px 2px rgba(46, 46, 46, 0.74)');
            } else {
                setNavBackground('transparent');
                setBoxShadow('none');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // State to control the offcanvas visibility
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Navbar expand="lg" className='sticky-top' style={{ backgroundColor: navBackground, boxShadow: boxShadow, transition: 'background-color 0.3s ease, box-shadow 0.3s ease' }}>
                <Container className="d-flex align-content-center justify-content-between">
                    {/* Toggle button on the left side for mobile */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} className="d-lg-none" />

                    {/* Centered logo on mobile */}
                    <Navbar.Brand className="mx-auto d-lg-none">
                        <Link to={"/"}>
                            <img style={{ width: 50 }} src={logo} alt='logo' />
                        </Link>
                    </Navbar.Brand>

                    {/* Desktop logo and menu */}
                    <Navbar.Brand className=" d-none d-lg-block">
                        <Link to={"/"}>
                            <img style={{ width: 65, marginLeft: "20px" }} src={logo} alt='logo' />
                        </Link>
                    </Navbar.Brand>

                    <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex justify-content-center">
                        <Nav className='mx-2' style={{ fontSize: "16px", justifyContent: "space-evenly" }}>
                            <Nav.Link className='ms-2'>
                                <Link className='text-decoration-none' style={{ color: 'black' }} to={'/about-us'}>About</Link>
                            </Nav.Link>
                            <Nav.Link className='ms-2'>
                                <Link className='text-decoration-none' style={{ color: 'black' }}>How It Works</Link>
                            </Nav.Link>
                            <Nav.Link className='ms-2'>
                                <Link className='text-decoration-none' style={{ color: 'black' }}>Pricing</Link>
                            </Nav.Link>
                            <Nav.Link className='ms-2'>
                                <Link className='text-decoration-none' style={{ color: 'black' }}>Contact</Link>
                            </Nav.Link>
                        </Nav>
                        <Nav className='mx-auto ' style={{ fontSize: "16px", justifyContent: "space-between" }}>
                        <Nav.Link className='ms-2'>
                                <Link to={'/preSorted-order'} className='text-decoration-none' style={{ color: 'black' }}>Pre-Sorted Order</Link>
                            </Nav.Link>
                            <Nav.Link className='ms-2'>
                                <Link to={'/track-order'} className='text-decoration-none' style={{ color: 'black' }}>Track Order</Link>
                            </Nav.Link>
                            <Nav.Link className='ms-2'>
                                <Link style={{ textDecoration: "none" }} to={"/checkout"}>
                                    <Button
                                        style={{
                                            backgroundColor: "#008F9D",
                                            color: "white",
                                            width: "42px",  // Set a fixed width for the button
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            position: "relative" // Ensures the badge is positioned correctly
                                        }}
                                        variant="outline-secondary"
                                        className="icon-button"
                                    >
                                        <FaShoppingCart  />
                                        {totalItems > 0 && (
                                            <span
                                                className='bag-quantity mb-3'
                                                style={{
                                                    position: "absolute", // Position the badge absolutely within the button
                                                    top: "-5px",
                                                    right: "-10px",
                                                    backgroundColor: "black",
                                                    borderRadius: "50%",
                                                    padding: "0px 5px",
                                                    fontSize: "12px",
                                                    color: "white"
                                                }}
                                            >
                                                {totalItems}
                                            </span>
                                        )}
                                    </Button>
                                </Link>
                            </Nav.Link>

                        </Nav>
                    </Navbar.Collapse>

                    {/* Mobile cart icon on the right */}
                    <div className="d-lg-none">
                        <Link style={{ textDecoration: "none" }} to={"/checkout"}>
                            <Button
                                style={{
                                    backgroundColor: "#008F9D",
                                    color: "white",
                                    width: "42px",  // Set a fixed width for the button
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "relative" // Ensures the badge is positioned correctly
                                }}
                                variant="outline-secondary"
                                className="icon-button"
                            >
                                <FaShoppingCart />
                                {totalItems > 0 && (
                                    <span
                                        className='bag-quantity mb-3'
                                        style={{
                                            position: "absolute", // Position the badge absolutely within the button
                                            top: "-5px",
                                            right: "-10px",
                                            backgroundColor: "black",
                                            borderRadius: "50%",
                                            padding: "0px 5px",
                                            fontSize: "12px",
                                            color: "white"
                                        }}
                                    >
                                        {totalItems}
                                    </span>
                                )}
                            </Button>
                        </Link>
                    </div>
                </Container>
                
            </Navbar>

            {/* Offcanvas for Mobile Screens */}
            <Offcanvas show={show} onHide={handleClose} placement="start" className="d-lg-none" style={{ width: '100%' }}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className='flex-column'>
                        <Nav.Link className='mx-3'>
                            <Offcanvas.Title className='text-dark  '>MENU</Offcanvas.Title>
                        </Nav.Link>
                        <Nav.Link onClick={handleClose} className='mx-3'>
                            <Link className='text-decoration-none ' style={{ color: 'black' }} to={'/about-us'}>About</Link>
                        </Nav.Link>
                        <Nav.Link onClick={handleClose} className='mx-3'>
                            <Link className='text-decoration-none' style={{ color: 'black' }}>How It Works</Link>
                        </Nav.Link>
                        <Nav.Link onClick={handleClose} className='mx-3'>
                            <Link className='text-decoration-none' style={{ color: 'black' }}>Pricing</Link>
                        </Nav.Link>
                        <Nav.Link onClick={handleClose} className='mx-3'>
                            <Link className='text-decoration-none' style={{ color: 'black' }}>Contact</Link>
                        </Nav.Link>
                        <Nav.Link className='mx-3'>
                                <Link to={'/preSorted-order'} className='text-decoration-none' style={{ color: 'black' }}>Pre-Sorted Order</Link>
                            </Nav.Link>
                        <Nav.Link onClick={handleClose} className='mx-3'>
                            <Link className='text-decoration-none' style={{ color: 'black' }}>Track Order</Link>
                        </Nav.Link>
                    </Nav>

                    <Row className='justify-content-center pt-4'>
                        <hr className='w-50' />
                        <Col md={12} sm={12} xs={12} className=' text-center pt-2'>
                            <img style={{ width: 70 }} src={logo} alt='logo' />
                            <ul className="list-unstyled">
                                <li style={{ fontSize: "11px", color: "black", paddingTop: "8px" }}>Full Service Pharmacy <br />
                                    Designed around your Life</li>
                            </ul>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default CustomNavbar;

import React, { useState, useEffect } from 'react';
import { Form, FormControl, InputGroup, Container, ListGroup } from 'react-bootstrap';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { useWindowWidth } from '@react-hook/window-size';
import axios from 'axios';
import config from '../../config/envConfig';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import './SearchBar.css';

function SearchBar({ onSearch, onSearchClick }) {
  const width = useWindowWidth();
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1); // Track selected suggestion index
  const navigate = useNavigate();

  const fetchSuggestions = _.debounce(async (term) => {
    if (term) {
      try {
        const response = await axios.get(`${config.API_URL}/api/products/search/suggestions`, {
          params: { search: term },
        });
        console.log("Suggestions response:", response.data); // Log the response to inspect its structure
        setSuggestions(response.data.data.products);
        console.log(suggestions);

      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    } else {
      setSuggestions([]);

    }
  }, 300);

  useEffect(() => {
    fetchSuggestions(searchTerm);
  }, [searchTerm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedIndex >= 0 && suggestions.length > 0) {
      handleSuggestionClick(suggestions[selectedIndex]);
    } else {
      onSearchClick();  // Trigger the scroll to products
      onSearch(searchTerm);
      setSuggestions([]);
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSuggestions([]);
    setSelectedIndex(-1);
    onSearch('');
  };

  const handleSuggestionClick = (suggestion) => {
    console.log('Selected suggestion:', suggestion); // Log the full suggestion object

    setSearchTerm(suggestion.title);

    setSuggestions([]);
    navigate('/productdetails', {
      state: {
        id: suggestion.id,
        pname: suggestion.title,
        desc: suggestion.description,
        images: suggestion.imageFilenames.map(filename => `${process.env.REACT_APP_API_URL}/api/images/${filename}`),
        price: `Rs. ${suggestion.price}`,
        specification: suggestion.specification,
        usageAndSafety: suggestion.usageAndSafety,
        warnings: suggestion.warnings,
        additionalInformation: suggestion.additionalInformation,
        precautions: suggestion.precautions

      },
    });
  };



  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <div className="search-bar-container">
      <Container>
        <Form className="d-flex justify-content-center" onSubmit={handleSubmit}>
          <InputGroup
            className="search-input-group position-relative"
            style={{
              width: width < 768 ? '100%' : '75%',
            }}
          >
            <FormControl
              type="search"
              placeholder="Search"
              aria-label="Search"
              className="search-bar border-0 text-white"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown} // Handle key events
              style={{
                backgroundColor: "#008F9D",
                outline: 'none',
                boxShadow: 'none',
                paddingRight: '2.5rem',
              }}
            />
            <InputGroup.Text
              style={{ backgroundColor: "#008F9D" }}
              className="search-icon text-white"
              onClick={handleSubmit}  // Trigger handleSubmit when the search icon is clicked
            >
              <FaSearch />
            </InputGroup.Text>
            {searchTerm && (
              <FaTimes
                onClick={clearSearch}
                style={{
                  position: 'absolute',
                  right: '3.5rem',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  color: 'white',
                }}
              />
            )}
            {suggestions.length > 0 && (
              <ListGroup
                className="position-absolute suggestion text-start text-info w-50 mt-5 border-0"
                style={{
                  maxHeight: '200px', // Set the maximum height
                  overflowY: 'auto',  // Enable vertical scrolling
                  zIndex: 1000, // Ensure the suggestions are on top of other elements
                }}
              >
                {suggestions.map((suggestion, index) => (
                  <ListGroup.Item

                    key={index}
                    action
                    onClick={() => handleSuggestionClick(suggestion)}
                    active={index === selectedIndex} // Highlight selected suggestion
                  >
                    {suggestion.title}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </InputGroup>
        </Form>
      </Container>
    </div>
  );
}

export default SearchBar;

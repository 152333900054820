import React, { useEffect, useRef } from 'react';
import '../App.css';
import Images from '../assets/Images';
import { Link } from 'react-router-dom';
import CustomNavbar from '../Components/CustomNavbar';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { FaCheckCircle ,FaPlay} from 'react-icons/fa'; // Removed unused icons for clarity
import Footer from '../Components/Footer';

function Home() {


  const care = [
    {
      name: "Automatic refills",
      desc: "We monitor and manage your refills with your doctors so you always have the medications you need.",
      img: Images.Autmomatic,
    },
    {
      name: "Behind the scenes support",
      desc: "We work directly with your doctors and insurance to save you time and hassle.",
      img: Images.clock,
    },
    {
      name: "Everything in one place",
      desc: "We organize all your medication, billing, and prescription details for easy access.",
      img: Images.everything,
    },
  ];
  const videoref=useRef(null)
  useEffect(()=>{
     if(videoref.current){
      videoref.current.playbackRate = 0.75;
     }
  },[])
  return (
    <>
      <CustomNavbar />
      {/* 247882 */}
      <Container fluid >
        {/* Section 1 - Full-Screen Video Background Section */}
        <div style={{ position: 'relative', height: '80vh'}}>
  {/* Responsive video, aligned to the right */}
  <div
    style={{
      position: 'absolute',
      top: 0,
      right: 0, // Align the video to the right
      display: 'flex',
      justifyContent: 'flex-end', // Align video content to the right within the container
      alignItems: 'center', // Vertically center the video within the container
      width: '100%',
      height: '100%',
      zIndex: '-1',
    }}
  >
    <video
      ref={videoref}
      src={Images.HomeVideo}
      autoPlay
      muted
      playsInline
      className=""
      style={{
        width: '70%', // Reduce the video width to 50%
        height: 'aut0', // Maintain aspect ratio
        objectFit: 'cover', // Ensure the video fills its container while maintaining aspect ratio
        borderRadius: '10px', // Optional: rounded corners for a softer look
      }}
    />
  </div>

  {/* Content Overlay */}
  <Container 
    className="ps-5 h-100 d-flex align-items-center"
    style={{ position: 'relative', zIndex: '1' }}
  >
    <Row className=" w-100 text-center text-md-start">
      <Col
        xs={12}
        md={6}
        className="d-flex flex-column justify-content-center align-items-center align-items-md-start"
      >
        <h1 className="fs-2" style={{ color: '#00909D', fontSize: '2rem' }}>
          Your Medication, Carefully Arranged and Delivered
        </h1>
        <p className="fs-6" style={{ color: '#00909D', fontSize: '1.2rem' }}>
          Simplify your medication routine with pre-sorted pills at no extra fee
        </p>
        <div className="d-flex mt-4 w-100 me-5 ">
          <Button
            variant="primary"
            style={{
              backgroundColor: 'black',
              borderColor: 'black',
              borderRadius: '50px',
            }}
          className='fs-6 '
          >
            
            Introducing Smart Medics Video
          </Button>
          <div
            className="ms-1 text-center "
            style={{
              backgroundColor: 'red',
              color: 'white',
              borderColor: 'red',
               borderRadius: '50px',
          
            }}
          >
            <FaPlay className="w-100 h-100 p-2  d-flex justify-content-center "  />
          </div>
        </div>
      </Col>
    </Row>
  </Container>
</div>

        {/* Calendar Section */}
        <Container className="mt-3">
          <Row>
            <Col xs={12} md={6}>
              <img
                src={Images.Calender}
                alt="calender"
                className="w-100 pt-5 h-100"
              />
            </Col>
            <Col xs={12} md={6} className="align-content-center">
              <h4 className="fs-2">Let us handle sorting your medications by date and time</h4>
              <p className="fs-4">
              No more waiting, sorting, or hunting for refills.
              </p>
              <div className="d-flex">
                <Link to="#" className="text-decoration-none ">
                  <h3 className="pt-1 fs-5" style={{ color: '#00909D' }}>
                    See how it works
                  </h3>
                </Link>
                <div className="ps-3 pt-1">
                  <img src={Images.Rightarrow} alt="arrow" className="w-25" />
                  <img src={Images.Rightarrow} alt="arrow" className="w-25" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Kind of Care Section */}
        <Container>
          {/* <Row>
            <Col xs={12} md={6} className="pt-5">
              <h5 className="mt-2" style={{ color: '#00909D' }}>
                FULL SERVICE
              </h5>
              <h1>A new kind of care</h1>
              <div>
                {care.map((item, index) => (
                  <Row key={index}>
                    <div className="d-flex pt-2">
                      <img
                        src={item.img}
                        alt={item.name}
                        style={{ width: '50px', height: '50px' }}
                      />
                      <div className="px-3">
                        <h5>{item.name}</h5>
                        <p className="d-block">{item.desc}</p>
                      </div>
                    </div>
                  </Row>
                ))}
              </div>
              <div className="d-flex">
                <Link to="#" className="text-decoration-none ms-5">
                  <h3 className="ps-3 pt-1 fs-6" style={{ color: '#00909D' }}>
                    See how it works
                  </h3>
                </Link>
                <div className="ps-3">
                  <img src={Images.Rightarrow} alt="arrow" className="w-25" />
                  <img src={Images.Rightarrow} alt="arrow" className="w-25" />
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <img src={Images.Dummy} alt="calender" className="w-75 ms-5" />
            </Col>
          </Row> */}
        </Container>

        {/* Mobile Hand Section */}
        <Container className="mt-5">
          <Row>
            <Col xs={12} md={6}>
              <h1 className="pt-4" style={{ color: '#00909D' }}>
                Smart Medics{' '}
                <span className="text-dark">at the ease of your fingertips</span>
              </h1>
              <div className="pt-5 fs-3 ms-2">
                <ul>
                  <li>Get regular updates</li>
                  <li>Track your medication</li>
                  <li>Get in touch with experts</li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={5} className="ms-2">
              <img src={Images.mobile} alt="calender" className="w-75 ms-5" />
            </Col>
          </Row>
        </Container>

        {/* Pricing Section */}
        <Row
          className="justify-content-center"
          style={{ backgroundColor: '#00909D' }}
        >
          <Col
            xs={12}
            md={7}
            style={{ height: '500px' }}
            className="align-content-center"
          >
            <h1 className="text-lg-center pt-5 text-white text-center">
              Our service is free, you only pay for your medications
            </h1>
            <div className="text-center">
              <Button
                style={{
                  backgroundColor: '#00909D',
                  borderColor: 'white',
                  borderRadius: '50px',
                }}
                type="submit"
                className="mt-5 w-md-50 w-lg-25 fs-4 px-4 py-2 mb-3"
              >
                Learn about pricing
              </Button>
            </div>
          </Col>
        </Row>

        {/* How We Deliver Section */}
        <Container className="mt-5">
          <h4 className="text-center pt-3 fs-3">
            HOW WE <span style={{ color: '#00909D' }}>DELIVER</span>
          </h4>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="pt-0">
              <ul className="list-unstyled fs-5 ms-5">
                <li className="mb-3 ps-5">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Express Delivery
                </li>
                <li className="mb-3 ps-5">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Cash On Delivery
                </li>
                <li className="mb-3 ps-5">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Real-Time Tracking
                </li>
                <li className="mb-3 ps-5">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Delivery Notification
                </li>
                <li className="mb-3 ps-5">
                  <FaCheckCircle style={{ color: '#00909D' }} /> Flexible Delivery Options
                </li>
              </ul>
            </Col>
            <Col xs={12} md={6}>
              <img src={Images.DeliveryTruck} alt="delivery truck" className="w-100" />
            </Col>
          </Row>
        </Container>
      </Container>

      <Footer />
    </>
  );
}

export default Home;

import React from 'react';
import { Carousel } from 'react-bootstrap';

const MyCarousel = ({ slides = [] }) => {
  return (
    <Carousel style={{ padding: '8%' }}>
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <img className="w-100" src={slide.image} alt={`Slide ${index + 1}`} />
          {slide.caption && (
            <Carousel.Caption>
              <h3>{slide.caption.title}</h3>
              <p>{slide.caption.text}</p>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default MyCarousel;

// Footer.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer style={{ backgroundColor: "black" }} className=" text-white mt-5">
      <Container>
        {isMobile ? (<>
          <Row>
            <Col md={4} sm={6} xs={12} className='pt-5 text-center'>
              <img className='m-4' src={logo} alt='logo' />
              <ul className="list-unstyled">
                <li style={{ fontSize: "20px" }}>Full Service Pharmacy <br />
                  Designed around your Life</li>


              </ul>
            </Col>
            <Col xs={6}>
              <h3 className='pt-3' style={{ color: "#00909D", fontWeight: "bolder" }}>Our Service</h3>
              <ul className="list-unstyled ">
                <li style={{ paddingTop: "2%" }}>How it Works</li>
                <li style={{ paddingTop: "10%" }}>Pharmacy Locations</li>
                <li style={{ paddingTop: "10%" }}>Online Consultation with
                  Pharmacists</li>
              </ul>
              <h3 style={{ color: "#00909D", fontWeight: "bolder", paddingTop: "50%" }}>About the Site</h3>
              <ul className="list-unstyled  ">
                <li style={{ paddingTop: "2%" }}>Customer Support</li>
                <li style={{ paddingTop: "10%" }}>Pre-sorted Medication Options</li>
                <li style={{ paddingTop: "10%" }}>Medication Managment Tools</li>
                <li style={{ paddingTop: "10%" }}>Convenient Refill Options</li>
              </ul>

            </Col>
            <Col className='pt-3' md={4} sm={6} xs={6} >
              <h3 style={{ color: "#00909D", fontWeight: "bolder" }}>Helpful Links</h3>
              <ul className="list-unstyled ">
                <Link className='text-decoration-none' style={{ color: "white" }}>
                <li style={{ paddingTop: "2%" }}>Careers</li>
                
                </Link>
                <Link  to={"/terms-conditions"} className='text-decoration-none' style={{ color: "white" }}>
                <li style={{ paddingTop: "10%" }}>Terms & Condition</li>
                
                </Link>
                <Link className='text-decoration-none' style={{ color: "white" }}>
                <li style={{ paddingTop: "10%" }}>Privacy Policy</li>
                
                </Link>
                <Link className='text-decoration-none' style={{ color: "white" }}>
                <li style={{ paddingTop: "10%" }}>Cancellation Policy</li>
                </Link>
                <Link className='text-decoration-none' style={{ color: "white" }}>
                <li style={{ paddingTop: "10%" }}>Delivery Policy</li>
                
                </Link>



              </ul>
              <h3 className='pt-4' style={{ color: "#00909D", fontWeight: "bolder" }}>About Us</h3>
              <ul className="list-unstyled ">
                <li style={{ paddingTop: "2%" }}>About our Company</li>
              </ul>
            </Col>


          </Row>
          <Row className="text-center ">
            <Col>
              <hr />
              <h5>123 Commercial Street, Suite 4567, Karachi. </h5>
              <ul className="list-unstyled ">

                <li className='pt-2'>
                  <a href="https://www.facebook.com" className="text-white me-3 " target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://www.twitter.com" className="text-white me-3" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="https://www.instagram.com" className="text-white me-3" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>

                  <a href="https://www.youtube.com" className="text-white" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>

              </ul>
              <p style={{ fontSize: "20px" }}> Smart Medics  |   All Rights Reserved.</p>

            </Col>
          </Row>
        </>) : (<>
          {/* desktop view--------------------------------------- */}
          <Container>
            <Row>
              <Col md={4} className='pt-3' >
                <img className='m-4 mx-0' src={logo} alt='logo' />
                <ul className="list-unstyled">
                  <li style={{ fontSize: "20px" }} >Full Service Pharmacy <br />
                    Designed around your Life</li>
                    <Row className='pt-5 'md={12}>
                    <h3 style={{ color: "#00909D", fontWeight: "bolder" }}>Contact</h3>
                  <ul className="list-unstyled ">
                    <li style={{ paddingTop: "2%" }}>123 Commercial
                      <br /> Street, Suite 4567, <br />
                      Karachi.</li>
                  </ul>
                    </Row>
                 
                  <li className='pt-4'>
                    <a href="https://www.facebook.com" className="text-white me-3 " target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://www.instagram.com" className="text-white me-3" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://www.twitter.com" className="text-white me-3" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://www.youtube.com" className="text-white" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>

                </ul>
              </Col>
              <Col md={4} className='p-5 ' >
                <h3 className='pt-3' style={{ color: "#00909D", fontWeight: "bolder" }}>Our Service</h3>
                <ul className="list-unstyled ">
                  <li style={{ paddingTop: "2%" }}>How it Works</li>
                  <li style={{ paddingTop: "3%" }}>Pharmacy Locations</li>
                  <li style={{ paddingTop: "3%" }}>Online Consultation with
                    Pharmacists</li>
                </ul>
                <h3 style={{ color: "#00909D", fontWeight: "bolder", paddingTop: "24%" }}>Helpful Links</h3>
                <ul className="list-unstyled ">
                  <li style={{ paddingTop: "2%" }}>Careers</li>
                  <Link  className='text-decoration-none' style={{ color: "white" }} to={"/terms-conditions"}>
                    <li style={{ paddingTop: "3%" }}>Terms & Condition</li>
                  </Link>
                  <Link  className='text-decoration-none' style={{ color: "white" }} to={"/privacy-policy"} >
                    <li style={{ paddingTop: "3%" }}>Privacy Policy</li>

                  </Link>
                  <Link to={"/cancellation-policy"} className='text-decoration-none' style={{ color: "white" }}>
                    <li style={{ paddingTop: "3%" }}>Cancellation Policy</li>

                  </Link>
                  <Link to={"/delivery-policy"} className='text-decoration-none' style={{ color: "white" }}>
                    <li style={{ paddingTop: "3%" }}>Delivery Policy</li>

                  </Link>


                </ul>
              </Col>
              <Col md={4} className='p-5'>
                <h3 className='pt-3' style={{ color: "#00909D", fontWeight: "bolder" }}>About the Site</h3>
                <ul className="list-unstyled ">
                  <li style={{ paddingTop: "2%" }}>Customer Support</li>
                  <li style={{ paddingTop: "3%" }}>Pre-sorted Medication Options</li>
                  <li style={{ paddingTop: "3%" }}>Medication Managment Tools</li>
                  <li style={{ paddingTop: "3%" }}>Convenient Refill Options</li>
                </ul>
                <h3 style={{ color: "#00909D", fontWeight: "bolder", paddingTop: "12%" }}>About Us</h3>
                <ul className="list-unstyled ">
                <Link to={"/about-us"} className='text-decoration-none' style={{ color: "white" }}>
                    <li style={{ paddingTop: "3%" }}>About Our Company</li>

                  </Link>
                </ul>
              </Col>
            </Row>
            <Row className="text-center ">
              <Col>
                <hr />
                <p style={{ fontSize: "20px" }}> Smart Medics |  All Rights Reserved.</p>
              </Col>
            </Row>
          </Container>
        </>)}
      </Container>

    </footer>
  );
};

export default Footer;

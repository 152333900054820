import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, ProgressBar, Spinner, Alert } from 'react-bootstrap';
import CustomNavbar from '../Components/CustomNavbar';
import Footer from '../Components/Footer';
import { HourglassSplit, Gear, Truck, CheckCircle } from 'react-bootstrap-icons';
import axios from 'axios';
import config from '../config/envConfig';

function TrackOrder() {
  const [orderId, setOrderId] = useState('');
  const [stages, setStages] = useState([
    { label: 'In Progress', completed: false, icon: <HourglassSplit /> },
    { label: 'Pending', completed: false, icon: <Gear /> },
    { label: 'Shipped', completed: false, icon: <Truck /> },
    { label: 'Delivered', completed: false, icon: <CheckCircle /> },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);

 
  const fetchOrderStatus = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${config.API_URL}/api/orders/${orderId}`);
      console.log(response);
      
      const orderData = response.data; // Assuming response.data contains the order details
      const orderStatus = orderData.status; // Extract the status

      console.log('Order Status:', orderStatus);

      setOrderDetails(orderData);

      // Update the stages based on the fetched status
      updateStages(orderStatus);
      
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('Failed to fetch order details. Please check the order number and try again.');
    }
  };

  const updateStages = (status) => {
    const normalizedStatus = status.toLowerCase(); // Normalize status to lowercase for comparison
  
    const newStages = stages.map((stage, index) => {
      const normalizedLabel = stage.label.toLowerCase(); // Normalize label to lowercase for comparison
      const currentStageIndex = stages.findIndex(s => s.label.toLowerCase() === normalizedStatus);
  
      return {
        ...stage,
        completed: index <= currentStageIndex, // Mark stages as completed up to the current stage
      };
    });
  
    setStages(newStages);
  };

  const handleTrackOrder = () => {
    if (orderId.trim()) {
      fetchOrderStatus();
    } else {
      setError('Please enter a valid order number.');
    }
  };

  const completedStages = stages.filter(stage => stage.completed).length;
  const progressPercentage = (completedStages / stages.length) * 100;

  return (
    <>
      <CustomNavbar />
      <Container className="mt-5">
        <Row className="justify-content-center mb-4">
          <h2 className="text-center mb-4">Track Your Order</h2>
          <Col xs={12} md={10} className="d-flex justify-content-center align-items-center">
            <Form.Control
              type="text"
              placeholder="Enter your order number"
              className="me-3 h-100"
              value={orderId}
              onChange={e => setOrderId(e.target.value)}
              style={{
                maxWidth: '400px',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            />
            <Button
              variant="primary"
              onClick={handleTrackOrder}
              disabled={loading}
              style={{
                backgroundColor: '#00909D',
                borderColor: '#00909D',
                borderRadius: '5px',
                padding: '10px 20px',
              }}
            >
              {loading ? <Spinner as="span" animation="border" size="sm" /> : 'TRACK NOW'}
            </Button>
          </Col>
        </Row>

        {error && (
          <Row className="justify-content-center">
            <Col xs={12} md={10}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}

        <Row className="justify-content-center">
          <Col xs={12} md={10}>
            <div className="p-4 shadow-lg">
              <div className="d-flex align-items-center position-relative flex-column flex-md-row">
                {stages.map((stage, index) => (
                  <div key={index} className="text-center" style={{ flex: '1 1 25%', zIndex: 1 }}>
                    <div
                      className={`rounded-circle mx-auto mb-2 ${stage.completed ? 'bg-primary text-white' : 'bg-light text-muted'}`}
                      style={{
                        width: '60px',
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        lineHeight: '20px',
                        fontSize: '1.5rem',
                        border: `2px solid ${stage.completed ? '#007bff' : '#ccc'}`,
                      }}
                    >
                      {stage.icon}
                    </div>
                    <small className={`d-block ${stage.completed ? 'fw-bold' : ''}`}>{stage.label}</small>
                  </div>
                ))}
                {completedStages > 1 && (
                  <ProgressBar
                    now={progressPercentage}
                    className="position-absolute progress-custom"
                    style={{
                      height: '8px',
                      width: 'calc(100% - 190px)',
                      top: '40%',
                      left: '90px',
                      right: '60px',
                      transform: 'translateY(-50%)',
                      zIndex: 0,
                      backgroundColor: '#e9ecef',
                    }}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>

        {orderDetails && (
          <Row className="shadow-lg mt-4">
            <Col xs={12} md={10} className="d-flex flex-column flex-md-row align-items-md-center p-4 ms-md-5">
              <div className="ps-md-5">
                <h3>Order Details</h3>
                <h6 className="pt-2">Order Number: {orderDetails._id}</h6>
                <h6 className="pt-0">Order Status: {orderDetails.status}</h6>

                <h6>Date Created: {new Date(orderDetails.dateCreated).toLocaleString()}</h6>
                <h6>Last Updated: {new Date(orderDetails.dateUpdated).toLocaleString()}</h6>
              </div>
            </Col>
          </Row>
        )}
      </Container>
      <Footer />
    </>
  );
}

export default TrackOrder;

import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Card, ListGroup, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import Images from '../assets/Images';

export default function OrderConfirmation() {
  const location = useLocation();
  const { orderId, cartItems, totalAmount, firstName, lastName, email, phone, address,} = location.state;
  const printRef = useRef();

  // Formatter for currency with thousand separator and two decimal places
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  // Get the current date and format it
  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-IN', {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    }).format(date);
  };
  const orderDate = formatDate(new Date());

  useEffect(() => {
    const preloadImages = (srcArray) => {
      return Promise.all(
        srcArray.map(
          (src) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.crossOrigin = 'anonymous'; // Handle cross-origin images
              img.src = src;
              img.onload = resolve;
              img.onerror = reject;
            })
        )
      );
    };

    const generatePDF = async () => {
      const imagesToLoad = [Images.orderheader, Images.orderfooter, ...cartItems.map((item) => item.img)];
      await preloadImages(imagesToLoad);

      const element = printRef.current;
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true, // Attempt to load cross-origin images
        allowTaint: false, // Prevent tainted canvas issues
        logging: true, // Enable logging to debug if issues arise
        backgroundColor: null, // Ensure no background color is added
        width: element.clientWidth, // Use the container's width for capturing the screenshot
      });

      const imgData = canvas.toDataURL('image/png');

      // Create an A4 PDF
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm

      // Set header and footer width to 100% of the PDF width
      // const headerFooterWidth = pdfWidth;
      const headerHeight = 25; // Adjust header height if needed
      const footerHeight = 10; // Adjust footer height if needed
      const headerPaddingTop = 10; // Padding from the top for the header in mm

      // Add padding for content
      const padding = 15; // Padding from left and right
      const contentWidth = pdfWidth - 2 * padding;
      const contentAreaHeight = pageHeight - headerHeight - footerHeight - headerPaddingTop - 2 * padding;

      let position = 0;
      const totalContentHeight = (canvas.height * contentWidth) / canvas.width;

      while (position < totalContentHeight) {
        // Add header image with top padding and full width
        pdf.addImage(Images.orderheader, 'PNG', 0, headerPaddingTop, pdfWidth, headerHeight);

        // Calculate the position of the content and its height on the page
        const contentPosition = headerPaddingTop + headerHeight;
        const remainingHeight = totalContentHeight - position;
        const contentHeightForPage = Math.min(remainingHeight, contentAreaHeight);

        // Add the content image with left and right padding
        pdf.addImage(
          imgData,
          'PNG',
          padding,
          contentPosition,
          contentWidth,
          contentHeightForPage
        );

        // Add footer image at the bottom and full width
        pdf.addImage(Images.orderfooter, 'PNG', 0, pageHeight - footerHeight, pdfWidth, footerHeight);

        position += contentAreaHeight;

        if (position < totalContentHeight) {
          pdf.addPage(); // Add a new page if there's more content to render
        }
      }
      pdf.output('dataurlnewwindow');
      pdf.save('order-confirmation.pdf');
    };

    generatePDF(); // Automatically generate and download PDF when component mounts
  }, []); // Empty dependency array to ensure useEffect runs only once

  return (
    <Container className="mt-5">
      {/* Display the header on the webpage */}
      <Row>
        <Col xs={12}>
          <img src={Images.orderheader} className="img-fluid w-100" alt="Order Header" />
        </Col>
      </Row>

      {/* This section is captured in the PDF */}
      <div ref={printRef}>
        <Row className="pt-3">
          <Col xs={12}>
            <div className="mb-4 mt-2">
              <div>
                <Row className="mt-5">
                  <Col xs={12} md={6}>
                    <Card.Title style={{ color: '#00909D' }}>Customer Details</Card.Title>

                    <ListGroup variant="flush" className="pt-2">
                      <div className='pt-1'>
                        <strong>Name:</strong> {firstName} {lastName}
                      </div>
                      <div className='pt-1'>
                        <strong>Email:</strong> {email}
                      </div>
                      <div className='pt-1'> 
                        <strong>Contact:</strong> {phone}
                      </div>
                      <div className='pt-1'>
                        <strong>Address:</strong> {address}
                      </div>
                    </ListGroup>
                  </Col>
                  <Col xs={12} md={6} className="d-flex justify-content-end mt-4">
                    <ListGroup variant="flush">
                      <div className='pt-1'>
                        <strong>Invoice Number:</strong> {orderId}
                      </div>
                      <div className='pt-1'>
                        <strong>Order Date:</strong> {orderDate} {/* Displaying the order date */}
                      </div>
                      <div className='pt-1'>
                        <strong>Tracking ID:</strong> {orderId}
                      </div>
                    </ListGroup>
                  </Col>
                </Row>
              </div>
            </div>
            <Card.Title className="mt-5" style={{ color: '#00909D' }}>
              Order Summary
            </Card.Title>
            {/* Table to display order summary */}
            <Table style={{borderTop:"2px solid black",borderBottom:"2px solid black"}} borderless className="mt-3">
              <thead > 
                <tr className="">
                  <th style={{borderBottom:"1px solid black"}}>Product Name</th>
                  <th className="text-center" style={{borderBottom:"1px solid black"}}>Quantity</th>
                  <th className="text-center" style={{borderBottom:"1px solid black"}}>Price (Rs)</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item, index) => (
                  <tr key={index}>
                    <td className="d-flex align-items-center">
                      <img
                        src={item.img}
                        alt={item.desc}
                        className="img-fluid"
                        style={{ width: '50px', marginRight: '10px' }}
                      />
                      <div>{item.pname}</div>
                    </td>
                    <td className="text-center">{item.quantity}</td>
                    <td className="text-center">{formatCurrency(parseFloat(item.price.replace('Rs. ', '')) * item.quantity)}</td>
                  </tr>
                ))}
                {/* Row for Total Amount */}
                <tr style={{borderTop:"2px solid black"}}>
                  <td colSpan="2" className="text-end" style={{borderTop:"2px solid black"}}><strong>Total Amount:</strong></td>
                  <td className="text-center me-5" style={{borderTop:"2px solid black"}}>RS: {formatCurrency(totalAmount)}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Card.Text className="mt-3 fs-6" style={{ color: '#00909D' }}>
          Thank you for your order.
        </Card.Text>
      </div>

      {/* Display the footer on the webpage */}
      <Row>
        <Col xs={12}>
          <img src={Images.orderfooter} className="img-fluid w-100" alt="Order Footer" />
        </Col>
      </Row>
    </Container>
  );
}

// src/services/Products.js
import axios from 'axios';
import config from '../config/envConfig';

export const fetchProducts = async (page, limit, searchTerm = '') => {
  try {
    const response = await axios.get(`${config.API_URL}/api/products`, {


      params: {
        type: 'full',
        page,
        limit,
        search: searchTerm, // Include search term in request
      }
    });
    if (response.data && response.data.data) {
      console.log(response);

      return {
        products: response.data.data.products.map(product => ({

          id: product._id,
          name: product.title,
          desc: product.description,
          price: `Rs. ${product.price}`,
          images: product.imageUrls,
          specification: product.specification,
          usageAndSafety: product.usageAndSafety,
          warnings: product.warnings,
          additionalInformation: product.additionalInformation,
          precautions: product.precautions
        })),
        totalPages: response.data.totalPages
      };

    }
    return { products: [], totalPages: 0 };
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error; // Re-throw to handle it in the component
  }
};

import axios from 'axios';
import config from '../config/envConfig';

export const submitOrderForm = async (formData) => {
  try {
    // Initialize FormData and append form data
    const submissionData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'images') {
        value.forEach((file) => {
          submissionData.append('images', file);
        });
      } else {
        submissionData.append(key, value);
      }
    });

    // Send a POST request to the backend with form data
    const response = await axios.post(
      `${config.API_URL}/api/prescription/add-prescription`,
      submissionData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 9000, // Add a timeout to prevent hanging requests
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error(`Error: ${error.response.status} - ${error.response.statusText}`);
      console.error('Response data:', error.response.data);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error:', error.message);
    }
    
    throw error;
  }
};

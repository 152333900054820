import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Forces the page to scroll to the top after route change, even when using back/forward buttons
    window.scrollTo(0, 0);
    // console.log(pathname);
    
  }, [pathname]);

  return null;
}

export default ScrollToTop;

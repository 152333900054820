import React, { useState, useRef, useEffect } from 'react';
import { Button, Container, Form, Alert } from 'react-bootstrap';
import CustomNavbar from '../Components/CustomNavbar';
import Footer from '../Components/Footer';
import MyCarousel from '../Components/CustomCarousel';
import { submitOrderForm } from '../services/PreSortedOrder';
import Swal from 'sweetalert2';
import images from '../assets/Images';
import { Dropzone } from '../Components/Dropzone';

export default function PerceptionOrder() {
const[files,setFiles]=useState([])

  const slides = [
    { image: images.Banner },
    { image: images.Banner },
    { image: images.Banner },
  ];
  // Initialize form data from localStorage or with default values
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem('preSortedOrderFormData');
    return savedData ? JSON.parse(savedData) : {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      address: '',
      images: [],
    };
  });

  const [error, setError] = useState(''); // State to store error message
  const [errors, setErrors] = useState({}); // State to track individual field errors
  const fileInputRef = useRef(null); // Ref to access the file input

  // Refs for each form field
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const addressRef = useRef(null);
  const imagesRef = useRef(null);

  const handleFileUpload = files => {
    setFormData(prev => ({ ...prev, images: files }));
  };

 

  useEffect(() => {
    const element = document.getElementById('prescription');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [error]);

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('preSortedOrderFormData', JSON.stringify(formData));
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validationErrors = {};
    let firstErrorRef = null;

    if (!formData.firstName.trim()) {
      validationErrors.firstName = 'First name is required.';
      firstErrorRef = firstErrorRef || firstNameRef;
    }
    if (!formData.lastName.trim()) {
      validationErrors.lastName = 'Last name is required.';
      firstErrorRef = firstErrorRef || lastNameRef;
    }
    if (!formData.phone.trim()) {
      validationErrors.phone = 'Phone number is required.';
      firstErrorRef = firstErrorRef || phoneRef;
    }
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'A valid email is required.';
      firstErrorRef = firstErrorRef || emailRef;
    }
    if (!formData.address.trim()) {
      validationErrors.address = 'Address is required.';
      firstErrorRef = firstErrorRef || addressRef;
    }
    if (!formData.images || formData.images.length === 0) {
      validationErrors.images = 'Please upload an image of your prescription.';
      firstErrorRef = firstErrorRef || imagesRef;
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      // Scroll to the first error
      if (firstErrorRef && firstErrorRef.current) {
        firstErrorRef.current.scrollIntoView({ behavior: 'smooth' });
        firstErrorRef.current.focus();
      }
      return;
    }

    setErrors({}); // Clear any existing errors

    try {
      const confirmOrder = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to place this order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00909D",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, place order!"
      });

      if (confirmOrder.isConfirmed) {
        const response = await submitOrderForm(formData);
        console.log('Order successfully submitted:', response); 
        setFiles([]);
        Swal.fire({
          title: "Order Submitted Successfully!",
          text: "You will be notified shortly.",
          icon: "success",
          confirmButtonColor: "#00909D",
        });

        // Reset the form after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          address: '',
          images: [],
        });
        localStorage.removeItem('preSortedOrderFormData');

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "There was an issue submitting your order. Please try again later.",
      });
    }
  };

  return (
    <>
      <CustomNavbar />
      <MyCarousel slides={slides} />
      <Container className="mt-5" id='prescription'>
        <h2 id='error' className="text-center" style={{ color: "#00909D" }}>Pre-Sorted Order Form</h2>
        <h6 className="pt-5" style={{ color: "red" }}>*Please fill out the following information</h6>
        {error && <Alert variant="danger">{error}</Alert>} {/* Display error message */}
        <Form encType='multipart/form-data' onSubmit={handleSubmit} style={{ borderColor: "red" }}>
          <Form.Group className="mb-3" controlId="formFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className={errors.firstName ? 'is-invalid' : ''}
              ref={firstNameRef}
            />
            {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className={errors.lastName ? 'is-invalid' : ''}
              ref={lastNameRef}
            />
            {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className={errors.phone ? 'is-invalid' : ''}
              ref={phoneRef}
            />
            {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={errors.email ? 'is-invalid' : ''}
              ref={emailRef}
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formAddress">
            <Form.Label>House Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className={errors.address ? 'is-invalid' : ''}
              ref={addressRef}
            />
            {errors.address && <div className="invalid-feedback">{errors.address}</div>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formImage">
            <Form.Label className="text-danger">Upload Image of a Prescription</Form.Label>
            <Dropzone onFileUpload={handleFileUpload} files={files} setFiles={setFiles} />
            {errors.images && <div className="text-danger">{errors.images}</div>}
            <div ref={imagesRef}></div>
          </Form.Group>

          <Button style={{ backgroundColor: "#00909D" }} type="submit" className="mt-4 w-100 " variant="primary">
            Submit
          </Button>
        </Form>
      </Container>
      <Footer />
    </>
  );
}
